import cn from "classnames";
import { useTranslation } from "next-i18next";
import React from "react";

import styles from "./PostInfo.module.scss";

import IconComment from "public/img/icon-comment.svg";
import FormattedDate from "components/blog/FormattedDate";
import { IPost } from "pages/blog/[slug]";

interface IPostInfoProps {
  date: string | undefined;
  comments_count: number | undefined;
  adapt?: boolean;
  mainPost?: IPost;
  isBlogPage?: boolean;
  blog?: boolean;
}

const PostInfo: React.FC<IPostInfoProps> = ({
  date = "",
  comments_count = 0,
  adapt,
  mainPost,
  isBlogPage,
  blog = true,
}) => {
  const { t } = useTranslation("blog");

  return (
    <div
      className={cn(styles.container, {
        [styles.adapt]: adapt,
        [styles.mainPost]: mainPost,
        [styles.blogPage]: isBlogPage,
      })}
    >
      <div className={styles.info}>
        <FormattedDate date={date} calendarIcon />
      </div>
      {blog && (
        <div className={styles.info}>
          <IconComment />
          {comments_count} {comments_count === 1 ? t("comment") : t("comments")}
        </div>
      )}
    </div>
  );
};

export default PostInfo;
