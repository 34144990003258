import React from "react";
import styles from "./PostItem.module.scss";

import Image from "next/legacy/image";
import Link from "next/link";
import { Skeleton } from "@mui/material";

import PostInfo from "components/blog/PostInfo/PostInfo";
import Tag from "components/blog/Tag/Tag";
import Dropdown from "components/shared/Dropdown/Dropdown";
import { ICommentsCount, IPost } from "models/model.post";

interface PostItemProps {
  data: IPost;
  isLoading?: boolean;
  commentsCountList: ICommentsCount[];
  isBlogPage?: boolean;
}

const PostItem: React.FC<PostItemProps> = ({ data, isLoading, commentsCountList, isBlogPage }) => {
  const links = data?.tags?.slice(0, 3); // get first 3 links
  const dropdownLinks = data?.tags?.slice(3); // get remaining links

  return (
    <Link href={`/blog/${data.alias}`}>
      <div className={styles.post_item_container}>
        <div className={styles.image_block}>
          {isLoading ? (
            <Skeleton variant="rectangular" height="100%" />
          ) : (
            <Image
              src={data.cover ? data.cover : "/../public/img/no-icon.webp"}
              layout="fill"
              objectFit="cover"
              loading="lazy"
              alt={data.title}
            />
          )}
        </div>
        <div className={styles.post_info}>
          <h2>{data.title}</h2>
          <PostInfo
            date={data?.created_at}
            comments_count={commentsCountList?.find((item) => item.post_id === data.id)?.count}
            isBlogPage={isBlogPage}
          />
          <p>{data.description}</p>
          {links && (
            <div className={styles.tags}>
              {links?.map((tag) => {
                return <Tag key={tag.id} title={tag.name} link={`/blog?tag=${tag.id}`} />;
              })}

              {dropdownLinks?.length > 0 && (
                <Dropdown
                  placement="top"
                  parent={<Tag title={`+${dropdownLinks?.length}`} />}
                  links={dropdownLinks.map((tag) => {
                    const object = {
                      item: (
                        <Tag
                          className={styles.dropdown_tag}
                          key={tag.id}
                          title={tag.name}
                          link={`/blog?tag=${tag.id}`}
                        />
                      ),
                    };

                    return object;
                  })}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default PostItem;
