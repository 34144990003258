import { useRouter } from "next/router";
import React, { SyntheticEvent } from "react";

import cn from "classnames";

import styles from "./Tag.module.scss";
import { useTranslation } from "next-i18next";

interface TagProps {
  title: string;
  dark?: boolean;
  link?: string;
  className?: string;
}

const Tag: React.FC<TagProps> = ({ title, link, dark = false, className }) => {
  const router = useRouter();
  const { t } = useTranslation("blog");

  const redirect = (e: SyntheticEvent) => {
    e.preventDefault();

    if (link) {
      router.push(link);
    }
  };

  return (
    <div
      className={cn(className, styles.social__tag, {
        [styles.dark]: dark,
      })}
      onClick={(e) => redirect(e)}
    >
      {t(title, title)}
    </div>
  );
};

export default Tag;
