import { Organization, WithContext, SoftwareApplication } from "schema-dts";

export const OrganizationLD = (url: string) => {
    const organizationJSON: WithContext<Organization> = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Cryptomus",
        "alternateName": "cryptomus.com",
        "url": url,
        "description": " Cryptomus provides a payment gateway, wallet, staking for rewards, P2P exchange, and a cryptocurrency converter supporting many currencies for users.",
        "sameAs": [
            "https://t.me/cryptomus",
            "https://www.reddit.com/user/Cryptomuscom/",
            "https://www.youtube.com/@cryptomus",
            "https://www.linkedin.com/company/cryptomusgateway/",
            "https://www.facebook.com/cryptomus",
            "https://instagram.com/cryptomus",
            "https://apps.apple.com/app/cryptomus/id6464404665",
            "https://skynet.certik.com/projects/cryptomus",
            "https://www.trustpilot.com/review/cryptomus.com"
        ],
        "contactPoint": [
            {
                "@type": "ContactPoint",        
                "contactType": "Customer Support",        
                "email": "support@cryptomus.com"  
            },    
            {        
                "@type": "ContactPoint",        
                "contactType": "Management",      
                "email": "robert@cryptomus.com"   
            }    
        ]
    }

    return organizationJSON;
};

export const SoftwareApplicationLD = (url: string) => {
    const softwareAppJSON: WithContext<SoftwareApplication> = {
        "@context": "https://schema.org",
        "@type": "SoftwareApplication",
        "name": "Cryptomus",
        "url": " https://apps.apple.com/ru/app/cryptomus/id6464404665",
        "description": " Cryptomus provides a payment gateway, wallet, staking for rewards, P2P exchange, and a cryptocurrency converter supporting many currencies for users.",
        "operatingSystem": "Android, iOS",
        "applicationCategory": "FinanceApplication",
        "author": {
            "@type": "Organization",
            "name": "Cryptomus",
            "url": url
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.7232186",
            "ratingCount": "17438"
        },
        "offers": [
            {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD",
                "availability": "https://schema.org/InStock"
            }
        ]      
    }; 
    
    return softwareAppJSON;
};
