import React, { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import IconCalendar from "./calendar.svg";
import styles from "./FormattedDate.module.scss";

interface IFormattedDateProps {
  date: string;
  calendarIcon?: boolean;
}

const FormattedDate: React.FC<IFormattedDateProps> = ({ date, calendarIcon = false }) => {
  const { i18n } = useTranslation("roadmap");

  const formatter = new Intl.DateTimeFormat(i18n.language, {
    day: "2-digit",
    year: "numeric",
    month: "long",
  });

  const dateTime = formatter.format(new Date(date));

  return (
    <div className={styles.wrapper}>
      {calendarIcon && <IconCalendar />}
      <time dateTime={dateTime}>{dateTime}</time>
    </div>
  );
};

export default FormattedDate;
