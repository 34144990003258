import React, { useEffect, useRef, useState } from "react";

import styles from "./Dropdown.module.scss";
import cn from "classnames";
import { useMediaQuery } from "../../../utils/hooks/useMediaQuery";

interface DropdownProps {
  parent: JSX.Element;
  links: {
    item: JSX.Element;
  }[];
  placement?: "bottom" | "top";
}

const Dropdown: React.FC<DropdownProps> = ({ parent, links, placement = "bottom" }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFloatRight, setIsFloatRight] = useState<boolean>(false);
  const dropdown = useRef<React.MutableRefObject<null>>(null);

  const handleClick = (): void => {
    setIsOpen(true);
  };

  const handleMouseEnter = (): void => {
    setIsOpen(true);
  };

  const handleMouseLeave = (): void => {
    setIsOpen(false);
  };

  const handleResize = () => {
    if (dropdown?.current && window?.innerWidth) {
      const { left } = dropdown.current.getBoundingClientRect();

      setIsFloatRight(window.innerWidth - left < 200);
    }
  };

  useEffect(() => {
    if (dropdown?.current) {
      handleResize();
      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }
  }, [dropdown]);

  return (
    <div ref={dropdown} className={styles.container} onMouseLeave={handleMouseLeave}>
      <div onClick={handleClick} onMouseEnter={handleMouseEnter}>
        {parent}
      </div>
      {isOpen && (
        <div className={cn(styles[placement], styles.dropdown)}>
          <div
            className={cn(styles.dropdown__menu, { [styles.dropdown__menu_right]: isFloatRight })}
          >
            {links.map((link) => {
              return <>{link.item}</>;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
